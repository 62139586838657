<template>
  <a-modal
    wrap-class-name="acctrans-apply"
    :visible="visible"
    :title="$t('pages_pay_187')"
    :mask-closable="false"
    :confirm-loading="loading"
    @ok="handleSubmit"
    @cancel="$emit('update:visible', false)"
  >
    <a-form
      ref="acctranstRef"
      :model="acctransForm"
      :rules="acctransRules"
      class="acctrans-form"
      layout="vertical"
    >
      <a-form-item name="toMerchantNo" :label="$t('pages_pay_165')">
        <a-input
          v-model:value="acctransForm.toMerchantNo"
          allow-clear
          :placeholder="$t('common_text_006') + $t('pages_pay_188')"
        />
      </a-form-item>
      <a-form-item name="toMerchantName" :label="$t('pages_pay_166')">
        <a-input
          v-model:value="acctransForm.toMerchantName"
          :placeholder="$t('common_text_006') + $t('pages_pay_189')"
          allow-clear
        />
      </a-form-item>
      <a-form-item name="currency">
        <template #label>
          <div class="flex-align">
            <span>{{ $t('pages_pay_167') }}</span>
            <span v-if="availableAmount !== null" class="amount-text">{{ $t('pages_pay_124') }}: {{ parseMoney(availableAmount) }}</span>
          </div>
        </template>
        <a-select
          v-model:value="acctransForm.currency"
          show-search
          allow-clear
          option-label-prop="label"
          option-filter-prop="label"
          :placeholder="$t('common_text_005') + $t('pages_pay_167')"
          @change="curChange"
        >
          <a-select-option
            v-for="item in curList"
            :key="item.currency"
            :value="item.currency"
            :label="`${item.currency} - ${item.currencyName}`"
          >
            <img :src="getCountryLogo(item.currency)" class="select-img-country">
            <span>{{ item.currency }} - {{ item.currencyName }}</span>
            <span style="margin-left: 8px; color: #999; font-size: 12px">
              {{ $t('pages_pay_124') }}: {{ item.amountText }}
            </span>
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item :label="$t('pages_pay_168')" name="amount">
        <a-input v-model:value="acctransForm.amount" allow-clear :placeholder="$t('common_text_006') + $t('pages_pay_168')" />
      </a-form-item>
      <a-form-item name="purpos" :label="$t('pages_pay_169')">
        <a-select
          v-model:value="acctransForm.purpos"
          allow-clear
          show-search
        >
          <a-select-option
            v-for="purpos in purposList"
            :key="purpos.code"
            :value="purpos.name"
          >{{ purpos.name }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item name="attach" :label="$t('pages_pay_170')">
        <a-textarea v-model:value="acctransForm.attach" :placeholder="$t('common_text_006') + $t('pages_pay_170')" allow-clear />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import { ref, reactive, toRefs, computed } from 'vue'
import { message } from 'ant-design-vue'
import { getCountryLogo, parseMoney, getAttrStatusList } from '@/util'
import { _pay } from '@/api'
import i18n from '@/locale'
export default {
  name: 'AccountInsert',
  props: {
    visible: {
      type: Boolean,
      default: () => { return false }
    }
  },
  setup (props, ctx) {
    const acctranstRef = ref(null)
    const validatorAmount = (rule, value) => {
      if (!value) {
        return Promise.reject(i18n.global.t('pages_pay_019'))
      } else if (!Number.isFinite(+value)) {
        return Promise.reject(i18n.global.t('pages_pay_020'))
      } else if ((value + '').split('.')[0].length > 16) {
        return Promise.reject(i18n.global.t('pages_pay_022'))
      } else if (state.acctransForm.currency === 'JPY' && (value + '').split('.').length > 1) {
        return Promise.reject(i18n.global.t('pages_pay_021'))
      } else if ((value + '').split('.').length > 1 && (value + '').split('.')[1].length > 2) {
        return Promise.reject(i18n.global.t('pages_pay_022'))
      } else if (+value > state.availableAmount) {
        return Promise.reject(i18n.global.t('pages_pay_296'))
      }
      return Promise.resolve()
    }
    const state = reactive({
      loading: false,
      availableAmount: null,
      curList: [],
      acctransForm: {
        toMerchantNo: '',
        toMerchantName: '',
        currency: null,
        amount: '',
        purpos: '',
        attach: ''
      },
      acctransRules: {
        toMerchantNo: [{ required: true }],
        toMerchantName: [{ required: true }],
        currency: [{ required: true, message: i18n.global.t('pages_pay_167') + i18n.global.t('common_text_042') }],
        amount: [{ required: true, validator: validatorAmount }],
        purpos: [{ required: true }]
      }
    })

    state.purposList = computed(() => {
      return getAttrStatusList('transPurpose')
    })

    const curChange = async (currency) => {
      state.availableAmount = null
      if (currency) {
        const account = state.curList.find(item => item.currency === currency)
        if (account.amount > 0) {
          const res = await _pay.getInAccAvailableAmount({ currency })
          if (res.data.succ) {
            const { data } = res.data
            state.availableAmount = data.availableAmount
            if (state.acctransForm.amount) acctranstRef.value.validateFields('amount')
          } else {
            state.acctransForm.currency = null
            state.acctransForm.amount = ''
            message.error(res.data.resMsg)
          }
        } else {
          state.availableAmount = 0
        }
      }
    }

    const handleSubmit = () => {
      acctranstRef.value.validateFields()
        .then(values => {
          state.loading = true
          const data = Object.assign({}, values, {})
          return _pay.transApply(data)
        })
        .then(res => {
          state.loading = false
          if (res.data.succ) {
            message.success(i18n.global.t('pages_pay_190'))
            ctx.emit('update:visible', false)
            ctx.emit('refresh')
            acctranstRef.value.resetFields()
          } else {
            message.error(res.data.resMsg)
          }
        })
        .catch(err => console.log(err))
    }

    const loadCurList = async () => {
      const res = await _pay.getRechargeSup()
      if (res.data.succ) {
        state.curList = res.data.data.currencyList.map(item => {
          return Object.assign({}, item, {
            amountText: parseMoney(item.amount, item.currency)
          })
        })
      }
    }

    loadCurList()

    return {
      acctranstRef,
      parseMoney,
      getCountryLogo,
      curChange,
      handleSubmit,
      ...toRefs(state)
    }
  }
}
</script>

<style lang="less" scoped>
.acctrans-apply {
  .acctrans-form {
    .amount-text {
      margin-left: 8px;
      color: #999;
      font-size: 12px;
    }
  }
}
</style>

