<template>
  <div id="tablePage">
    <a-pagination
      size="small"
      :total="total"
      :current="pageOpts.pageNum"
      :page-size="pageOpts.pageSize"
      show-size-changer
      show-quick-jumper
      :show-total="total =>
        isEng ?
          `Total ${total} items` :
          `共 ${total} 条`"
      @change="(page, pageSize) => $emit('update-page', page, pageSize, 'page')"
    />
  </div>
</template>
<script>
import { ref } from 'vue'
export default {
  name: 'TablePage',
  props: {
    total: {
      type: Number,
      default: () => 0
    },
    pageOpts: {
      type: Object,
      default: () => {
        return {
          pageNum: 0,
          pageSize: 10
        }
      }
    }
  },
  setup() {
    const isEng = ref(localStorage.getItem('locale-language') === 'en')
    return {
      isEng
    }
  }
}
</script>
<style lang="less">
#tablePage {
  display: flex;
  justify-content: flex-end;
  margin-top: 18px;
  padding-right: 24px
}
</style>

