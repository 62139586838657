<template>
  <div id="accountTransfer">
    <header-nav :content="`${$t('pages_router_007')} / ${ $t('pages_router_030')}`" />
    <div class="common-card-body">
      <div v-if="getUsedCheck(pageLabs, 150701) && getUsedCheck(pageLabs, 170299)" class="edit-btn">
        <a-button
          shape="round"
          type="primary"
          @click="() => applyVisible = true"
        >
          {{ $t('pages_pay_163') }}
        </a-button>
      </div>
      <div class="search-form ant-round-form">
        <div class="part flex-align">
          <span class="part-label">{{ $t('pages_pay_164') }}: </span>
          <a-input
            v-model:value="searchQuery.orderNo"
            allow-clear
            @pressEnter="loadInAccTrans(1)"
            @blur="loadInAccTrans(1)"
          />
        </div>
        <div class="part flex-align">
          <span class="part-label">{{ $t('pages_pay_165') }}: </span>
          <a-input
            v-model:value="searchQuery.toMerchantNo"
            allow-clear
            @pressEnter="loadInAccTrans(1)"
            @blur="loadInAccTrans(1)"
          />
        </div>
        <div class="part flex-align">
          <span class="part-label">{{ $t('pages_pay_114') }}: </span>
          <a-select
            v-model:value="searchQuery.status"
            allow-clear
            @change="loadInAccTrans(1)"
          >
            <a-select-option
              v-for="status in statusList"
              :key="status.code"
              :value="status.code"
            >{{ status.name }}</a-select-option>
          </a-select>
        </div>
        <div class="part flex-align">
          <span class="part-label">{{ $t('pages_pay_077') }}: </span>
          <a-range-picker
            v-model:value="searchQuery.dateOpts"
            format="YYYY/MM/DD"
            value-format="YYYY-MM-DD"
            @change="loadReceiveFlows(1)"
          />
        </div>
      </div>
      <div class="table-part">
        <a-table
          :scroll="{ x: 1500 }"
          :loading="loading"
          :pagination="false"
          :custom-header-row="() => { return {class: 'my-table-tr'}}"
          :columns="columns"
          :data-source="accTrans"
        >
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'edit'">
              <a-space style="width: 100%; justify-content: space-between">
                <a-button
                  size="small"
                  type="link"
                  @click="transReview(record, true)"
                >{{ $t('pages_receive_009') }}</a-button>
                <a-divider v-if="record.status === '01' && getUsedCheck(pageLabs, 150702)" type="vertical" />
                <a-button
                  v-if="record.status === '01' && getUsedCheck(pageLabs, 150702)"
                  size="small"
                  type="link"
                  danger
                  @click="transReview(record, false)"
                >{{ $t('pages_pay_016') }}</a-button>
              </a-space>
            </template>
          </template>
        </a-table>
        <table-page
          :total="total"
          :page-opts="searchQuery"
          @update-page="updatePage"
        />
      </div>
    </div>
    <a-modal
      :visible="repeatVisible"
      :mask-closable="false"
      :ok-button-props="{ disabled: countNum !== 0 }"
      :ok-text="countNum === 0 ? $t('pages_pay_068') : countNum + $t('pages_pay_186')"
      @ok="handleOk"
      @cancel="() => repeatVisible = false"
    >
      <template #title>
        <div class="flex-align">
          <exclamation-circle-outlined style="color: #faad14; font-size: 24px; margin-right: 12px;" />
          <span>{{ $t('pages_pay_179') }}</span>
        </div>
      </template>
      <div v-if="repeatList.length < 10" class="tip-body" style="margin-top: -12px">
        <span>{{ $t('pages_pay_180') }}</span>
        <span>{{ repeatDesc }}</span>
      </div>
      <div v-else class="tip-body" style="margin-top: -12px">
        <div>{{ $t('pages_pay_180') }}{{ repeatSize }}{{ $t('pages_pay_181') }}{{ hisCt }}{{ $t('pages_pay_182') }}</div>
        <div style="margin-top: 12px">
          {{ $t('pages_pay_183') }}
          <span style="color: #ff4d4f">{{ $t('pages_pay_184') }}</span>
          {{ $t('pages_pay_185') }}
        </div>
      </div>
    </a-modal>
    <acctrans-review
      v-model:visible="visible"
      :read-only="readOnly"
      :acctrans-info="acctransInfo"
      @refresh="loadInAccTrans"
    />
    <acctrans-apply
      v-model:visible="applyVisible"
      @refresh="loadInAccTrans"
    />
  </div>
</template>
<script>
import { reactive, toRefs, computed } from 'vue'
import HeaderNav from '@/components/header-nav'
import TablePage from '@/components/table-page'
import AcctransReview from './components/acctrans-review'
import AcctransApply from './components/acctrans-apply'
import { getAttrStatusList, getTradeStatusName, parseMoney, getUsedCheck } from '@/util'
import { _pay, _account } from '@/api'
import { message } from 'ant-design-vue'
import i18n from '@/locale'
import moment from 'moment'
export default {
  name: 'AccountTransfer',
  components: {
    'header-nav': HeaderNav,
    'table-page': TablePage,
    'acctrans-review': AcctransReview,
    'acctrans-apply': AcctransApply
  },
  setup(props) {
    let timer = ''
    const state = reactive({
      countNum: 5,
      loading: true,
      visible: false,
      applyVisible: false,
      repeatVisible: false,
      searchQuery: {
        pageNum: 1,
        pageSize: 10,
        orderNo: '',
        toMerchantNo: '',
        status: '',
        dateOpts: []
      },
      total: 0,
      readOnly: true,
      acctransInfo: {},
      newTransItem: {},
      accTrans: [],
      pageLabs: [],
      repeatList: [],
      repeatSize: 0,
      repeatDesc: '',
      hisCt: 0,
      columns: [
        { key: 'orderNo', dataIndex: 'orderNo', title: i18n.global.t('pages_pay_164'), width: 200 },
        { key: 'toMerchantNo', dataIndex: 'toMerchantNo', title: i18n.global.t('pages_pay_165'), width: 200 },
        { key: 'toMerchantName', dataIndex: 'toMerchantName', title: i18n.global.t('pages_pay_166'), width: 180, ellipsis: true },
        { key: 'currency', dataIndex: 'currency', title: i18n.global.t('pages_pay_167'), width: 150 },
        { key: 'amount', dataIndex: 'amountText', title: i18n.global.t('pages_pay_168'), width: 180 },
        { key: 'purpose', dataIndex: 'purpose', title: i18n.global.t('pages_pay_169'), width: 180, ellipsis: true },
        { key: 'attach', dataIndex: 'attach', title: i18n.global.t('pages_pay_170'), width: 180, ellipsis: true },
        { key: 'fromApplyTime', dataIndex: 'fromApplyAt', title: i18n.global.t('pages_pay_171'), width: 180 },
        { key: 'fromApproveTime', dataIndex: 'fromApproveAt', title: i18n.global.t('pages_pay_172'), width: 180 },
        { key: 'status', dataIndex: 'statusText', title: i18n.global.t('pages_pay_114'), width: 120, align: 'center', fixed: 'right' },
        { key: 'edit', title: i18n.global.t('common_text_001'), align: 'center', fixed: 'right', width: localStorage.getItem('locale-language') === 'en' ? 200 : 150 }
      ]
    })

    state.statusList = computed(() => {
      return getAttrStatusList('transStatus')
    })

    const updatePage = (page, pageSize) => {
      state.searchQuery.pageNum = page
      state.searchQuery.pageSize = pageSize
      loadInAccTrans()
    }

    const handleOk = () => {
      state.repeatVisible = false
      loadTransFee(state.newTransItem)
    }

    const transReview = async (record, readOnly) => {
      state.readOnly = readOnly
      if (readOnly) {
        state.visible = true
        state.acctransInfo = record
      } else {
        const res = await _pay.checkRecentRepeatOrder({
          batchApplyNo: record.orderNo,
          business: 'ACCT_TRANSFER'
        })
        if (res.data.succ && res.data.data) {
          clearInterval(timer)
          const { list, repeatSize, hisCt } = res.data.data

          state.countNum = 5
          state.newTransItem = record
          state.repeatList = list
          state.repeatSize = repeatSize
          state.repeatDesc = list.map(item => item.errorMsg).join(',')
          state.hisCt = hisCt
          state.repeatVisible = true

          timer = setInterval(() => {
            state.countNum--
            if (state.countNum <= 0) clearInterval(timer)
          }, 1000)
        } else {
          loadTransFee(record)
        }
      }
    }

    const loadTransFee = async (record) => {
      const res = await _pay.inAccCalcFee({
        currency: record.currency,
        amount: record.amount
      })
      if (res.data.succ) {
        const { feeAmt, feeCurty, feeRateId } = res.data.data
        state.acctransInfo = Object.assign({}, record, {
          feeAmt: feeAmt,
          feeAmtText: parseMoney(feeAmt, feeCurty),
          feeCurty: feeCurty,
          feeRateId: feeRateId
        })
        state.visible = true
      } else {
        message.error(res.data.resMsg)
      }
    }

    const loadInAccTrans = async (pageNum) => {
      state.loading = true
      state.repeatVisible = false
      if (!state.searchQuery.dateOpts) state.searchQuery.dateOpts = []
      if (pageNum) state.searchQuery.pageNum = pageNum
      const { dateOpts } = state.searchQuery
      const query = Object.assign({}, state.searchQuery, {
        applyBeginDate: dateOpts[0] ? `${dateOpts[0]} 00:00:00` : '',
        applyEndDate: dateOpts[1] ? `${dateOpts[1]} 23:59:59` : ''
      })
      const res = await _pay.getInAccTrans(query)
      if (res.data.succ) {
        state.accTrans = res.data.data.list.map(item => {
          return Object.assign({}, item, {
            statusText: getTradeStatusName(item.status, 'transStatus'),
            fromApplyAt: item.fromApplyTime ? moment(item.fromApplyTime).format('YYYY-MM-DD') : '',
            fromApproveAt: item.fromApproveTime ? moment(item.fromApproveTime).format('YYYY-MM-DD') : '',
            amountText: item.amount ? parseMoney(item.amount, item.currency) : '-'
          })
        })
        state.total = res.data.data.total
      }
      state.loading = false
    }

    const loadChildrenMenu = async () => {
      let pageLabs = []
      const res1 = await _account.getChildrenMenu({ parentId: 1507 })
      const res2 = await _account.getChildrenMenu({ parentId: 1702 })
      if (res1.data.succ) pageLabs = res1.data.data || []
      if (res2.data.succ) pageLabs = pageLabs.concat(res2.data.data || [])
      state.pageLabs = pageLabs
    }

    loadInAccTrans()
    loadChildrenMenu()

    return {
      getUsedCheck,
      transReview,
      handleOk,
      updatePage,
      loadInAccTrans,
      loadChildrenMenu,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
#accountTransfer {
  .edit-btn {
    text-align: right;
    padding: 0 18px;
  }
  .table-part {
    margin-top: 12px;
  }
}
</style>

