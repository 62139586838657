<template>
  <div id="acctransReview">
    <a-modal
      width="53%"
      wrap-class-name="acctrans-review"
      :visible="visible"
      :title="$t('pages_pay_174')"
      :mask-closable="false"
      @cancel="$emit('update:visible', false)"
    >
      <div class="detail-body-i">
        <div class="body-list">
          <div class="title">
            <account-book-filled class="ic-transact" />
            <span>{{ $t('pages_pay_173') }}</span>
          </div>
          <div class="area-list">
            <div class="area">
              <span style="font-weight: 500">{{ $t('pages_pay_106') }}</span>
              <div class="text">{{ acctransInfo.orderNo }}</div>
            </div>
            <div class="area">
              <span style="font-weight: 500">{{ $t('pages_pay_175') }}</span>
              <div class="text">{{ acctransInfo.fromApplyAt }}</div>
            </div>
            <div class="area">
              <span style="font-weight: 500">{{ $t('pages_pay_176') }}</span>
              <div class="text">{{ acctransInfo.toMerchantName }}</div>
            </div>
            <div class="area">
              <span style="font-weight: 500">{{ $t('pages_pay_177') }}</span>
              <div class="text">{{ acctransInfo.toMerchantNo }}</div>
            </div>
            <div class="area">
              <span style="font-weight: 500">{{ $t('pages_pay_167') }}</span>
              <div class="text">
                <img :src="getCountryLogo(acctransInfo.currency)">
                <span>{{ acctransInfo.currency }}</span>
              </div>
            </div>
            <div class="area">
              <span style="font-weight: 500">{{ $t('pages_pay_168') }}</span>
              <div class="text">{{ acctransInfo.amountText }}</div>
            </div>
            <div class="area">
              <span style="font-weight: 500">{{ $t('pages_pay_169') }}</span>
              <div class="text">{{ acctransInfo.purpose }}</div>
            </div>
            <div class="area">
              <span style="font-weight: 500">{{ $t('pages_pay_170') }}</span>
              <div class="text">
                <a-tooltip :title="acctransInfo.attach">
                  <div class="ellipsis">{{ acctransInfo.attach }}</div>
                </a-tooltip>
              </div>
            </div>
            <div v-if="!readOnly" class="area">
              <span style="font-weight: 500">{{ $t('pages_pay_178') }}</span>
              <div class="text">
                <img :src="getCountryLogo(acctransInfo.feeCurty)">
                <span>{{ acctransInfo.feeCurty }}</span>
              </div>
            </div>
            <div v-if="!readOnly" class="area">
              <span style="font-weight: 500">{{ $t('pages_pay_082') }}</span>
              <div class="text">{{ acctransInfo.feeAmtText }}</div>
            </div>
          </div>
        </div>
      </div>
      <template #footer>
        <div v-if="readOnly" class="flex-align" style="justify-content: end">
          <a-button type="primary" @click="$emit('update:visible', false)">{{ $t('common_text_024') }}</a-button>
        </div>
        <div v-else class="flex-align">
          <a-button type="primary" danger :loading="rejectLoading" @click="handleReview(false)">{{ $t('pages_pay_100') }}</a-button>
          <div style="margin-left: auto">
            <a-button @click="$emit('update:visible', false)">{{ $t('common_text_003') }}</a-button>
            <a-button type="primary" :loading="reviewLoading" @click="handleReview(true)">{{ $t('pages_pay_099') }}</a-button>
          </div>
        </div>
      </template>
    </a-modal>
    <password-modal
      v-model:visible="passwordVisible"
      @pass="reviewFlag('02')"
    />
  </div>
</template>
<script>
import { reactive, toRefs } from 'vue'
import { getCountryLogo } from '@/util'
import { _pay } from '@/api'
import { message } from 'ant-design-vue'
import PasswordModal from '@/components/password-modal'
import i18n from '@/locale'
export default {
  name: 'AcctransReview',
  components: {
    'password-modal': PasswordModal
  },
  props: {
    visible: {
      type: Boolean,
      default: () => { return false }
    },
    readOnly: {
      type: Boolean,
      default: () => { return true }
    },
    acctransInfo: {
      type: Object,
      default: () => { return {} }
    }
  },

  setup (props, ctx) {
    const state = reactive({
      rejectLoading: false,
      reviewLoading: false,
      passwordVisible: false
    })

    const handleReview = (pass) => {
      if (pass) state.passwordVisible = true
      else reviewFlag('03')
    }

    const reviewFlag = (status) => {
      state.passwordVisible = false
      if (status === '02') state.reviewLoading = true
      else state.rejectLoading = true
      const data = {
        status,
        feeCurty: props.acctransInfo.feeCurty,
        feeAmount: props.acctransInfo.feeAmt,
        feeRateId: props.acctransInfo.feeRateId,
        orderNo: props.acctransInfo.orderNo
      }
      _pay.transConfirm(data)
        .then(res => {
          state.reviewLoading = false
          state.rejectLoading = false
          if (res.data.succ) {
            message.success(i18n.global.t(status === '03' ? 'pages_pay_103' : 'pages_pay_099'))
            ctx.emit('update:visible', false)
            ctx.emit('refresh')
          }
        })
    }

    return {
      getCountryLogo,
      reviewFlag,
      handleReview,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../styles/detail-form.less';
.acctrans-review {
  .area-list {
    margin-bottom: -24px;
  }
}
</style>

